<template></template>

<script>
export default {
  inject:['reload'],
  data() {
    return {

    };
  },
  methods: {
    bindWx: function() {
      let code = this.$route.query.code;
      this.$axios({
        url: this.HOST + "/jiameng/index/bindWxchat",
        method: "post",
        data: { code: code }
      }).then(res => {
        if(res.data.status == 1){
          this.$message({
            type:'info',
            message:'微信绑定成功'
          });
          history.back();
        }
      });
    },
  },
  created: function() {
    this.bindWx();
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>